import RootState from '@vue-storefront/core/types/RootState'
import { ActionTree } from 'vuex'
import InterestingCategoriesState from '../types/InterestingCategoriesState'
import { InterestingCategoriesCategoryService } from '../data-resolver/CategoryService'
import * as types from './mutation-types'

const actions: ActionTree<InterestingCategoriesState, RootState> = {
  loadCategories: async ({ commit, getters }) => {
    if (getters.getCategories.length > 0) {
      return getters.getCategories
    }
    const categories = await InterestingCategoriesCategoryService.getCategories({})
    commit(types.SET_CATEGORIES, categories)
    return categories
  }
}

export default actions
