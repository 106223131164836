import { GetterTree } from 'vuex'
import RootState from '@vue-storefront/core/types/RootState'
import ProductState from '../types/ProductState'

const getters: GetterTree<ProductState, RootState> = {
  getCurrentProduct: state => state.current,
  getCurrentProductConfiguration: state => state.current_configuration,
  getCurrentProductOptions: state => state.current_options,
  getOriginalProduct: (state, getters) => {
    if (!getters.getCurrentProduct) return null
    return state.original || {
      ...getters.getCurrentProduct,
      id: getters.getCurrentProduct.parentId || getters.getCurrentProduct.id
    }
  },
  getParentProduct: state => state.parent,
  getProductsSearchResult: state => state.list,
  getProducts: (state, getters) => getters.getProductsSearchResult.items,
  getProductGallery: state => state.productGallery,
  getProductRelated: state => state.related,
  getCurrentCustomOptions: state => state.current_custom_options,
  getCharacteristicAttributes: (state) => state.characteristicAttributes,
  getAllCharacteristicAttributeCodes: (state) => state.characteristicAttributeCodes,
  getTopCharacteristicAttributeCodes: (state) => state.topCharacteristicAttributeCodes,
  getProductAttributeLabel: (state) => state.productAttributeLabelMap,
  getInStockProductVariantsIds: (state) => state.inStockProductVariants.map(e => e.id) || [],
  getVarusPerfectProducts: (state) => state.varusPerfectProducts,
  getProductVariantsIds: (state, getters) => {
    const properties = getters.getCurrentProduct?.property_data || [];

    if (!properties.length) return []

    return properties.flatMap(item => item.options.map(e => e.product_id));
  }
}

export default getters
