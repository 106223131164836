import { currentStoreView } from '@vue-storefront/core/lib/multistore'
import getApiEndpointUrl from '@vue-storefront/core/helpers/getApiEndpointUrl';
import { TaskQueue } from '@vue-storefront/core/lib/sync'
import { getOptimizedFields, canCache } from '@vue-storefront/core/modules/catalog/helpers/search'
import { isServer } from '@vue-storefront/core/helpers'
import { quickSearchByQuery } from 'src/search/adapter/api-search-query-varus/search'
import { isOnline } from '@vue-storefront/core/lib/search'
import config from 'config'
import { DataResolver } from '@vue-storefront/core/data-resolver/types/DataResolver'
import { prepareProducts } from '@vue-storefront/core/modules/catalog/helpers/prepare'
import { configureProducts } from '@vue-storefront/core/modules/catalog/helpers/configure'
import Product from '@vue-storefront/core/modules/catalog/types/Product'
import { SearchQuery } from 'storefront-query-builder'
import { entityKeyName } from '@vue-storefront/core/lib/store/entities'
import { StorageManager } from '@vue-storefront/core/lib/storage-manager'
import { doPlatformPricesSync } from '@vue-storefront/core/modules/catalog/helpers'
import { Logger } from '@vue-storefront/core/lib/logger'
import {
  getCurrentShopId,
  getSQPPShopKey,
  // isCurrentDarkstore,
  isCurrentNewPost,
  isCurrentPickup,
  productRemap
} from 'theme/store/checkout/helpers'
import { storeProductToCache } from 'theme/store/catalog/helpers/search'
import AppliedSort from 'storefront-query-builder/src/types/AppliedSort';
import { ProductExtended } from 'theme/store/catalog/types/product-extended';
import { recursiveDeepCopy } from 'theme/helpers/object';
import modulesConfig from '$modules/config';
import {
  sortAvailabilityCheck,
  // sortDarkstore,
  sortNewPost,
  sortGeneralDelivery, sortGeneralPickup
} from '$modules/product/data-resolver/utils/sort';

type SortScript = {
  sortScript: AppliedSort,
  additional: AppliedSort[]
}

const additionalScript = (scriptMap, catId) => {
  try {
    if (!scriptMap?.position || !catId) return []

    const cat = {
      ...(scriptMap.position || {})
    }

    cat.field = cat.field.replace('{category}', catId)

    return [cat]
  } catch (e) {
    return []
  }
}

const getSortScript = (sort: string, shopId: number, catId: number | null = null): SortScript => {
  const sortScript = {
    field: '',
    options: {
      order: 'desc'
    }
  }

  if (!sort) {
    return {
      sortScript,
      additional: []
    }
  }

  const sortParts = sort.split(':') || []
  let scriptMap = config.products.sortByAttributesScriptMap.find(item => item.attribute === sortParts[0])

  scriptMap = scriptMap ? JSON.parse(JSON.stringify(scriptMap)) : null

  if (scriptMap) {
    sortScript.field = (scriptMap.sortScript?.field || '').replace('sqpp_data_*', `sqpp_data_${shopId}`)
    sortScript.options = scriptMap.sortScript?.options ? recursiveDeepCopy(scriptMap.sortScript?.options) : { order: sortParts[1] || 'desc' }

    if (!sortScript.options.order) {
      sortScript.options.order = sortParts[1] || 'desc'
    }
  }

  const additional = additionalScript(scriptMap, catId)

  return {
    sortScript,
    additional
  }
}

const getProducts = async ({
  query,
  start = 0,
  size = 50,
  sort = '',
  excludeFields = null,
  includeFields = null,
  configuration = null,
  options: {
    prefetchGroupProducts = !isServer,
    fallbackToDefaultWhenNoAvailable = true,
    setProductErrors = false,
    setConfigurableProductOptions = config.cart.setConfigurableProductOptions,
    filterUnavailableVariants = config.products.filterUnavailableVariants,
    assignProductConfiguration = false,
    separateSelectedVariant = false
  } = {},
  onlyInStock = false,
  loadDiscountedProducts = false,
  onlyMarkdown = false,
  availableToday = false,
  availabilityCheckSort = false,
  manualSort = false
}: DataResolver.ProductSearchOptions): Promise<DataResolver.ProductsListResponse> => {
  const isCacheable = canCache({ includeFields, excludeFields })
  const { excluded, included: initialFields } = getOptimizedFields({ excludeFields, includeFields })
  const shopId = await getCurrentShopId();
  const isNewPost = await isCurrentNewPost();
  const isPickup = await isCurrentPickup();
  // const isDarkstore = await isCurrentDarkstore();

  const {
    key,
    isRegion,
    included,
    deliveryKey
  } = await getSQPPShopKey(initialFields, shopId, isNewPost)

  const inStockKey = `${key}.in_stock`

  if (availableToday) {
    query.applyFilter({ key: `${key}.availability.today`, value: { 'eq': true } });
  }

  if (availableToday && isPickup) {
    query.applyFilter({ key: `${key}.availability.pickup`, value: { 'eq': true } });
  }

  if (onlyInStock) {
    query.applyFilter({ key: inStockKey, value: { 'eq': true } });
  }

  if (onlyInStock && isNewPost) {
    query.applyFilter({ key: `${key}.availability.shipping`, value: { 'eq': true } });
  }

  if (!manualSort && (isRegion || isNewPost || !loadDiscountedProducts)) {
    query.applyFilter({ key: 'markdown_id', value: { 'nin': null } });
  }

  if (!manualSort && (!isRegion && onlyMarkdown)) {
    query.applyFilter({ key: 'markdown_id', value: { 'gt': 0 } });
  }

  const oldAppliedSort = query.getAppliedSort().filter(i => i.field !== inStockKey)

  const baseSort = []

  let source

  if (manualSort) {
    source = null
  } else if (availabilityCheckSort) {
    source = sortAvailabilityCheck(key)
  } else if (isNewPost) {
    source = sortNewPost(deliveryKey || key, key)
  } else if (isPickup) {
    source = sortGeneralPickup(key)
  } else {
    source = sortGeneralDelivery(key)
  }

  const availability = source ? [{
    'field': '_script',
    'options': {
      'type': 'number',
      'order': 'desc',
      'script': {
        'lang': 'painless',
        'source': source
      }
    }
  }] : []

  if (sort && !availabilityCheckSort) {
    const cats = (query?.getAppliedFilters() || [])?.find(i => i.attribute === 'category_ids')?.value?.in

    const catId = Array.isArray(cats) ? cats[0] : cats

    const { sortScript, additional } = getSortScript(sort, shopId, catId);

    if (sortScript.field) {
      sort = ''
      oldAppliedSort.push(...additional, sortScript)
    }
  }

  query['_appliedSort'] = availabilityCheckSort ? [...availability] : [...baseSort, ...availability, ...oldAppliedSort]

  const {
    items = [],
    attributeMetadata = [],
    aggregations = [],
    total,
    perPage
  } = await quickSearchByQuery({
    query,
    start,
    size,
    entityType: 'product_v2',
    sort,
    excludeFields: excluded,
    includeFields: included,
    shopId
  })

  const productItems = items.map(productRemap(key, isRegion, deliveryKey))

  const products = prepareProducts(productItems)

  for (const product of products) { // we store each product separately in cache to have offline access to products/single method
    if (isCacheable) { // store cache only for full loads
      storeProductToCache(product, 'sku', shopId)
    }
  }

  const configuredProducts = await configureProducts({
    products,
    attributes_metadata: attributeMetadata,
    configuration,
    options: {
      prefetchGroupProducts,
      fallbackToDefaultWhenNoAvailable,
      setProductErrors,
      setConfigurableProductOptions,
      filterUnavailableVariants,
      assignProductConfiguration,
      separateSelectedVariant
    },
    excludeFields: excluded,
    includeFields: included
  })

  const configuredProductsItems = configuredProducts.map(productRemap(key, isRegion))

  return {
    items: configuredProductsItems,
    perPage,
    start,
    total,
    aggregations,
    attributeMetadata
  }
}

const getProductRenderList = async ({
  skus,
  isUserGroupedTaxActive,
  userGroupId,
  token
}): Promise<DataResolver.ProductsListResponse> => {
  const { i18n, storeId } = currentStoreView()
  let url = [
    `${getApiEndpointUrl(config.products, 'endpoint')}/render-list`,
    `?skus=${encodeURIComponent(skus.join(','))}`,
    `&currencyCode=${encodeURIComponent(i18n.currencyCode)}`,
    `&storeId=${encodeURIComponent(storeId)}`
  ].join('')
  if (isUserGroupedTaxActive) {
    url = `${url}&userGroupId=${userGroupId}`
  }

  if (token && !config.users.tokenInHeader) {
    url = `${url}&token=${token}`
  }

  try {
    const task = await TaskQueue.execute({ url, // sync the cart
      payload: {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          ...(token && config.users.tokenInHeader ? { authorization: `Bearer ${token}` } : {})
        },
        mode: 'cors'
      },
      callback_event: 'prices-after-sync'
    })
    return task.result as DataResolver.ProductsListResponse
  } catch (err) {
    console.error(err)
    return { items: [] }
  }
}

const getProduct = async (options: { [key: string]: string }, key: string): Promise<Product> => {
  let searchQuery = new SearchQuery()
  searchQuery = searchQuery.applyFilter({ key: key, value: { 'eq': options[key] } })
  const { items = [] } = await getProducts({
    query: searchQuery,
    size: 1,
    includeFields: modulesConfig.singleProduct.includeFields,
    excludeFields: modulesConfig.singleProduct.excludeFields,
    configuration: { sku: options.childSku },
    manualSort: true,
    options: {
      prefetchGroupProducts: true,
      assignProductConfiguration: true
    },
    loadDiscountedProducts: !!options.loadDiscountedProducts || false
  })
  return items[0] || null
}

const getProductFromCache = async (options: { [key: string]: string }, key: string): Promise<Product> => {
  try {
    const shopId = await getCurrentShopId();
    const cacheKey = entityKeyName(key, options[key], shopId);
    const cache = StorageManager.get('elasticCache')
    const result = await cache.getItem(cacheKey)

    if (result !== null) {
      if (config.products.alwaysSyncPlatformPricesOver) {
        if (!config.products.waitForPlatformSync) {
          await doPlatformPricesSync([result])
        } else {
          doPlatformPricesSync([result])
        }
      }
      const { excluded, included } = getOptimizedFields({
        includeFields: modulesConfig.singleProduct.includeFields,
        excludeFields: modulesConfig.singleProduct.excludeFields
      })
      const [product] = await configureProducts({
        products: [result],
        attributes_metadata: [],
        configuration: { [key]: options.childSku || options.sku || options[key] },
        options: {
          prefetchGroupProducts: true,
          setConfigurableProductOptions: config.cart.setConfigurableProductOptions,
          filterUnavailableVariants: config.products.filterUnavailableVariants,
          assignProductConfiguration: true
        },
        excludeFields: excluded,
        includeFields: included
      })
      return product
    } else {
      return getProduct(options, key)
    }
  } catch (err) {
    // report errors
    if (err) {
      Logger.error(err, 'product')()
    }
    return getProduct(options, key)
  }
}

const getProductByKey = async ({ options, key, skipCache }: DataResolver.ProductByKeySearchOptions): Promise<ProductExtended> => {
  if (!isOnline()) {
    return getProductFromCache(options, key)
  }
  const result = skipCache
    ? await getProduct(options, key)
    : await getProductFromCache(options, key)
  return result
}

export const ProductService: DataResolver.ProductService = {
  getProducts,
  getProductRenderList,
  getProductByKey
}
