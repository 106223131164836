export const SN_PRODUCT = 'product'
export const PRODUCT_SET_PAGED_PRODUCTS = SN_PRODUCT + '/SET_PRODUCTS'
export const PRODUCT_ADD_PAGED_PRODUCTS = SN_PRODUCT + '/ADD_PRODUCTS'
export const PRODUCT_SET_RELATED = SN_PRODUCT + '/SET_RELATED'
export const PRODUCT_SET_CURRENT = SN_PRODUCT + '/SET_CURRENT'
export const PRODUCT_SET_CURRENT_OPTIONS = SN_PRODUCT + '/SET_CURRENT_OPTIONS'
export const PRODUCT_RESET_CURRENT = SN_PRODUCT + '/RESET_CURRENT'
export const PRODUCT_SET_ORIGINAL = SN_PRODUCT + '/SET_ORIGINAL'
export const PRODUCT_SET_CURRENT_CONFIGURATION = SN_PRODUCT + '/SET_CURRENT_CONFIGURATION'
export const PRODUCT_SET_PARENT = SN_PRODUCT + '/SET_PARENT'
export const PRODUCT_SET_CUSTOM_OPTION = SN_PRODUCT + '/SET_CUSTOM_OPTION'
export const PRODUCT_SET_CUSTOM_OPTION_VALIDATOR = SN_PRODUCT + '/SET_CUSTOM_OPTION_VALIDATOR'
export const PRODUCT_SET_BUNDLE_OPTION = SN_PRODUCT + '/SET_BUNDLE_OPTION'
export const PRODUCT_SET_GALLERY = SN_PRODUCT + '/SET_PRODUCT_GALLERY'
// remove later
export const CATALOG_UPD_PRODUCTS = SN_PRODUCT + '/UPD_PRODUCTS'
export const CATALOG_UPD_RELATED = SN_PRODUCT + '/UPD_RELATED'
export const CATALOG_SET_PRODUCT_CURRENT = SN_PRODUCT + '/SET_PRODUCT_CURRENT'
export const CATALOG_SET_PRODUCT_ORIGINAL = SN_PRODUCT + '/SET_PRODUCT_ORIGINAL'
export const CATALOG_RESET_PRODUCT = SN_PRODUCT + '/RESET_PRODUCT_ORIGINAL'
export const CATALOG_SET_PRODUCT_PARENT = SN_PRODUCT + '/SET_PARENT'
export const CATALOG_UPD_CUSTOM_OPTION = SN_PRODUCT + '/SET_CUSTOM_OPTION'
export const CATALOG_UPD_BUNDLE_OPTION = SN_PRODUCT + '/UPD_BUNDLE_OPTION'
export const CATALOG_ADD_CUSTOM_OPTION_VALIDATOR = SN_PRODUCT + '/ADD_CUSTOM_OPTION_VALIDATOR'
export const CATALOG_UPD_GALLERY = SN_PRODUCT + '/SET_GALLERY'
export const CATALOG_SET_BREADCRUMBS = SN_PRODUCT + '/SET_BREADCRUMBS'
export const SET_DISCOUNT_PRODUCTS = SN_PRODUCT + '/SET_DISCOUNT_PRODUCTS'
export const SET_TOP_PRODUCTS = SN_PRODUCT + '/SET_TOP_PRODUCTS'
export const SET_NEW_PRODUCTS = SN_PRODUCT + '/SET_NEW_PRODUCTS'
export const SET_RECOMMENDED_PRODUCTS = SN_PRODUCT + '/SET_RECOMMENDED_PRODUCTS'
export const SET_CHARACTERISTIC_ATTRIBUTES = SN_PRODUCT + '/CHARACTERISTIC_ATTRIBUTES';
export const SET_CHARACTERISTIC_ATTRIBUTE_CODES = SN_PRODUCT + '/CHARACTERISTIC_ATTRIBUTE_CODES';
export const SET_TOP_CHARACTERISTIC_ATTRIBUTE_CODES = SN_PRODUCT + '/TOP_CHARACTERISTIC_ATTRIBUTE_CODES';
export const SET_PRODUCT_ATTRIBUTE_OPTIONS = SN_PRODUCT + '/PRODUCT_ATTRIBUTE_OPTIONS';
export const SET_VARUS_PERFECT_PRODUCTS = SN_PRODUCT + '/VARUS_PERFECT_PRODUCTS';
export const SET_IN_STOCK_PRODUCT_VARIANTS = SN_PRODUCT + '/IN_STOCK_PRODUCT_VARIANTS';
export const UPDATE_CURRENT_PRODUCT_STOCK = SN_PRODUCT + '/UPDATE_CURRENT_PRODUCT_STOCK';
