import { MutationTree } from 'vuex'
import InterestingCategoriesState from '../types/InterestingCategoriesState'
import * as types from './mutation-types'

const mutations: MutationTree<InterestingCategoriesState> = {
  [types.SET_CATEGORIES]: (state, categories) => {
    state.categories = categories
  }
}

export default mutations
