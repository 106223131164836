import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { InterestingCategoriesStore } from './store';

export const InterestingCategoriesModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.['interesting-categories']) {
    InterestingCategoriesStore.state = fromCache['interesting-categories']
  }

  store.registerModule('interesting-categories', InterestingCategoriesStore)
}
