import { quickSearchByQuery } from '@vue-storefront/core/lib/search';
import { SearchQuery } from 'storefront-query-builder'

const getCategories = async ({
  size = 50,
  start = 0,
  sort = 'position:asc'
}) => {
  const searchQuery = new SearchQuery()
  const response = await quickSearchByQuery({ entityType: 'homepage_category', query: searchQuery, sort: sort, size: size, start: start })
  return response.items
}

export const InterestingCategoriesCategoryService = {
  getCategories
}
