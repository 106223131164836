export const sortGeneralDelivery = (key: string) => (`
int score = 0;

score = doc['${key}.availability.shipping'].value ? 2 : score;
score = doc['${key}.availability.other_regions'].value ? 2 : score;
score = doc['${key}.availability.pickup'].value ? 2 : score;
score = doc['${key}.availability.other_market'].value ? 2 : score;
score = doc['${key}.availability.delivery'].value ? 4: score;

score += doc['${key}.in_stock'].value ? 1 : 0;

if (doc.containsKey('markdown_id') && !doc['markdown_id'].empty && score > 2) {
    score = 3;
}

return score;
`)

export const sortGeneralPickup = (key: string) => (`
int score = 0;

score = doc['${key}.availability.shipping'].value ? 2 : score;
score = doc['${key}.availability.other_regions'].value ? 2 : score;
score = doc['${key}.availability.other_market'].value ? 2 : score;
score = doc['${key}.availability.delivery'].value ? 2: score;
score = doc['${key}.availability.pickup'].value ? 4: score;

score += doc['${key}.in_stock'].value ? 1 : 0;

if (doc.containsKey('markdown_id') && !doc['markdown_id'].empty && score > 2) {
    score = 3;
}

return score;
`)

export const sortNewPost = (key: string, inStockKey: string) => (`
int score = 0;

score = doc['${key}.availability.other_regions'].value ? 1 : score;
score = doc['${key}.availability.shipping'].value ? 2 : score;

score += doc['${inStockKey}.in_stock'].value ? 1 : 0;

return score;
`)

export const sortAvailabilityCheck = (key: string) => (`
int score = 0;

score = doc['${key}.availability.delivery'].value ? 1 : score;
score = doc['${key}.availability.pickup'].value ? 1 : score;
score = doc['${key}.availability.shipping'].value ? 2 : score;

return score;
`)

// export const sortDarkstore = (key: string) => (`
// int score = 0;
//
// score = doc['${key}.availability.shipping'].value ? 2 : score;
// score = doc['${key}.availability.other_regions'].value ? 2 : score;
// score = doc['${key}.availability.pickup'].value ? 2 : score;
// score = doc['${key}.availability.other_market'].value ? 2 : score;
// score = doc['${key}.availability.delivery'].value ? 4: score;
//
// if (doc.containsKey('markdown_id') && !doc['markdown_id'].empty && score > 2) {
//     score = 3;
// }
//
// return score;
// `)
