import { StorefrontModule } from '@vue-storefront/core/lib/modules';
import { ProductStore } from './store'

export const ProductModule: StorefrontModule = function ({ store }, fromCache) {
  if (fromCache?.product) {
    ProductStore.state = fromCache.product
  }

  store.registerModule('product', ProductStore)
}
